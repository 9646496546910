import {Navigate, RouteObject} from 'react-router-dom';
import React from 'react';
import {authGuard} from '@common/auth/guards/auth-route';

const lazyRoute = async (
  cmp: keyof typeof import('@app/dashboard/routes/dashboard-routes.lazy'),
) => {
  const exports = await import('@app/dashboard/routes/dashboard-routes.lazy');
  return {
    Component: exports[cmp],
  };
};

export const sharedDashboardRoutes = [
  // links
  {
    path: 'links',
    lazy: () => lazyRoute('LinksDatablePage'),
  },
  {
    path: 'links/:linkId',
    lazy: () => lazyRoute('LinkClicksReportPage'),
  },

  // biolinks
  {
    path: 'biolinks',
    lazy: () => lazyRoute('BioLinksDatatablePage'),
  },
  {
    path: 'biolinks/:biolinkId',
    lazy: () => lazyRoute('BiolinkClicksReportPage'),
  },
  {
    path: 'biolinks/:biolinkId/edit',
    element: <Navigate to="content" replace />,
  },
  {
    path: 'biolinks/:biolinkId/edit/:tabName',
    lazy: () => lazyRoute('BiolinkEditorPage'),
  },

  // groups
  {
    path: 'link-groups',
    lazy: () => lazyRoute('LinkGroupsDatatablePage'),
  },
  {
    path: 'link-groups/:groupId',
    lazy: () => lazyRoute('LinkGroupClicksReportPage'),
  },
  {
    path: 'link-groups/:groupId/links',
    lazy: () => lazyRoute('LinkGroupsLinksDatatablePage'),
  },
  {
    path: 'link-groups/:groupId/links/:linkId',
    lazy: () => lazyRoute('LinkClicksReportPage'),
  },

  // domains
  {
    path: 'custom-domains',
    lazy: () => lazyRoute('DomainsDatatablePage'),
  },

  // tracking pixels
  {
    path: 'pixels',
    lazy: () => lazyRoute('TrackingPixelsDatablePage'),
  },

  // overlays
  {
    path: 'link-overlays',
    lazy: () => lazyRoute('LinkOverlaysDatatablePage'),
  },
  {
    path: 'link-overlays/new',
    lazy: () => lazyRoute('CreateLinkOverlayPage'),
  },
  {
    path: 'link-overlays/:overlayId/edit',
    lazy: () => lazyRoute('UpdateLinkOverlayPage'),
  },
];

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    loader: () => authGuard(),
    lazy: () => lazyRoute('BelinkDashboardLayout'),
    children: [
      {index: true, lazy: () => lazyRoute('AllClicksReportPage')},

      // link pages
      {
        path: 'link-pages',
        lazy: () => lazyRoute('LinkPagesDatatablePage'),
      },
      {
        path: 'link-pages/new',
        lazy: () => lazyRoute('CreateLinkPage'),
      },
      {
        path: 'link-pages/:pageId/edit',
        lazy: () => lazyRoute('EditLinkPage'),
      },

      ...sharedDashboardRoutes,
    ],
  },
];
