import './app.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {CommonProvider} from '@common/core/common-provider';
import * as Sentry from '@sentry/react';
import {LinkGroup} from '@app/dashboard/link-groups/link-group';
import {Link, LinkType} from '@app/dashboard/links/link';
import {TrackingPixel} from '@app/dashboard/tracking-pixels/tracking-pixel';
import {LinkOverlay} from '@app/dashboard/link-overlays/link-overlay';
import {LandingPageContent} from '@app/landing/landing-page-content';
import {ignoredSentryErrors} from '@common/errors/ignored-sentry-errors';
import {Product} from '@common/billing/product';
import {FetchCustomPageResponse} from '@common/custom-page/use-custom-page';
import {Biolink} from '@app/dashboard/biolink/biolink';
import {appRouter} from '@app/app-router';
import {GetLandingPageStatsResponse} from '@app/landing/use-landing-page-stats';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {rootEl} from '@ui/root-el';
import {BaseBackendSettings} from '@common/core/settings/base-backend-settings';
import {BaseBackendUser} from '@common/auth/base-backend-user';
import {BaseBackendBootstrapData} from '@common/core/base-backend-bootstrap-data';

declare module '@ui/bootstrap-data/bootstrap-data' {
  interface BootstrapData extends BaseBackendBootstrapData {
    loaders?: {
      landingPage?: {
        products: Product[];
        stats: GetLandingPageStatsResponse['stats'];
      };
      customPage?: FetchCustomPageResponse;
      linkeablePage?: {
        linkeable: Link | LinkGroup | Biolink;
        path: string;
      };
    };
  }
}

declare module '@common/http/value-lists' {
  interface FetchValueListsResponse {
    overlays: LinkOverlay[];
    pixels: TrackingPixel[];
    groups: LinkGroup[];
  }
}

declare module '@ui/settings/settings' {
  interface Settings extends BaseBackendSettings {
    homepage: {
      appearance: LandingPageContent;
      type: 'loginPage' | 'registerPage' | string;
      value?: string;
    };
    links: {
      gchart_api_key?: string;
      alias_min?: number;
      alias_max?: number;
      alias_content?: string;
      redirect_time?: number;
      enable_type?: boolean;
      default_type?: LinkType;
      min_len?: number;
      max_len?: number;
      retargeting?: boolean;
      pixels?: boolean;
      dash_footer?: boolean;
      home_expiration?: string;
      homepage_creation?: boolean;
      homepage_stats?: boolean;
      homepage_pricing?: boolean;
      blacklist?: {
        domains?: string[];
        keywords?: string[];
      };
      google_safe_browsing_key?: string;
      phishtank_key?: string;
      subdomain_matching?: boolean;
    };
    biolink: {
      show_branding?: boolean;
      branding_img?: string;
    };
    ads?: {
      biolink_top?: string;
      splash_top?: string;
      splash_bottom?: string;
      dashboard?: string;
      frame?: string;
      landing?: string;
      link_page?: string;
      disable?: boolean;
    };
  }
}

declare module '@ui/types/user' {
  interface User extends BaseBackendUser {
    //
  }
}

const data = getBootstrapData();
const sentryDsn = data.settings.logging.sentry_public;
if (sentryDsn && import.meta.env.PROD) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: ignoredSentryErrors,
    release: data.sentry_release,
  });
}

createRoot(rootEl).render(<CommonProvider router={appRouter} />);
