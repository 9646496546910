import {RouteObject} from 'react-router-dom';
import {sharedDashboardRoutes} from '@app/dashboard/routes/dashboard-routes';
import {lazyAdminRoute} from '@common/admin/routes/lazy-admin-route';

export const appAdminRoutes: RouteObject[] = [
  {
    path: '',
    lazy: () => lazyAdminRoute('BelinkAdminReportPage'),
    children: [
      {path: 'clicks', lazy: () => lazyAdminRoute('AdminClicksReport')},
      {path: 'visitors', lazy: () => lazyAdminRoute('AdminVisitorsReport')},
      {index: true, lazy: () => lazyAdminRoute('AdminVisitorsReport')},
    ],
  },
  ...sharedDashboardRoutes,
];
