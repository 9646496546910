import {
  IAppearanceConfig,
  MenuSectionConfig,
  SeoSettingsSectionConfig,
} from '@common/admin/appearance/types/appearance-editor-config';
import {message} from '@ui/i18n/message';
import {lazyAdminRoute} from '@common/admin/routes/lazy-admin-route';

export const AppAppearanceConfig: IAppearanceConfig = {
  preview: {
    defaultRoute: 'dashboard',
    navigationRoutes: ['dashboard'],
  },
  sections: {
    'landing-page': {
      label: message('Landing Page'),
      position: 1,
      previewRoute: '/',
      routes: [
        {
          path: 'landing-page',
          lazy: () => lazyAdminRoute('LandingPageAppearanceForm'),
          children: [
            {
              index: true,
              lazy: () => lazyAdminRoute('LandingPageSectionGeneral'),
            },
            {
              path: 'action-buttons',
              lazy: () => lazyAdminRoute('LandingPageSectionActionButtons'),
            },
            {
              path: 'primary-features',
              lazy: () => lazyAdminRoute('LandingPageSectionPrimaryFeatures'),
            },
            {
              path: 'secondary-features',
              lazy: () => lazyAdminRoute('LandingPageSecondaryFeatures'),
            },
          ],
        },
      ],
    },
    // missing label will get added by deepMerge from default config
    // @ts-ignore
    menus: {
      config: {
        positions: [
          'link-page-navbar',
          'dashboard-navbar',
          'dashboard-sidebar',
          'homepage-navbar',
          'footer',
          'footer-secondary',
        ],
        availableRoutes: [
          '/dashboard',
          '/dashboard/biolinks',
          '/dashboard/links',
          '/dashboard/link-groups',
          '/dashboard/custom-domains',
          '/dashboard/link-overlays',
          '/dashboard/link-pages',
          '/dashboard/tracking-pixels',
        ],
      } as MenuSectionConfig,
    },
    // @ts-ignore
    'seo-settings': {
      config: {
        pages: [
          {
            key: 'landing-page',
            label: message('Landing page'),
          },
          {
            key: 'link-page',
            label: message('Link page'),
          },
          {
            key: 'biolink-page',
            label: message('Biolink page'),
          },
          {
            key: 'link-group-page',
            label: message('Link group page'),
          },
        ],
      } as SeoSettingsSectionConfig,
    },
  },
};
