import {createBrowserRouter} from 'react-router-dom';
import {GuestRoute} from '@common/auth/guards/guest-route';
import {LandingPage} from '@app/landing/landing-page';
import React from 'react';
import {authGuard} from '@common/auth/guards/auth-route';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {DynamicHomepage} from '@common/ui/other/dynamic-homepage';
import {RootErrorElement, RootRoute} from '@common/core/common-provider';
import {authRoutes} from '@common/auth/auth-routes';
import {notificationRoutes} from '@common/notifications/notification-routes';
import {adminRoutes} from '@common/admin/routes/admin-routes';
import {dashboardRoutes} from '@app/dashboard/routes/dashboard-routes';
import {LinkeableRenderer} from '@app/short-links/linkeable-renderer';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {checkoutRoutes} from '@common/billing/checkout/routes/checkout-routes';
import {billingPageRoutes} from '@common/billing/billing-page/routes/billing-page-routes';
import {commonRoutes} from '@common/core/common-routes';

function ShortLinkFallback() {
  const linkeableData = getBootstrapData().loaders?.linkeablePage;
  return linkeableData ? (
    <LinkeableRenderer linkeable={linkeableData.linkeable} />
  ) : (
    <NotFoundPage />
  );
}

export const appRouter = createBrowserRouter(
  [
    {
      id: 'root',
      element: <RootRoute />,
      errorElement: <RootErrorElement />,
      children: [
        {
          path: '/',
          element: (
            <DynamicHomepage
              homepageResolver={() => {
                // when automatic subdomain matching is enabled, "alias.site.com"
                // might match a short link, so we need to check for it here as well
                const linkeableData = getBootstrapData().loaders?.linkeablePage;
                if (linkeableData) {
                  return (
                    <LinkeableRenderer linkeable={linkeableData.linkeable} />
                  );
                }
                return (
                  <GuestRoute>
                    <LandingPage />
                  </GuestRoute>
                );
              }}
            />
          ),
        },
        ...authRoutes,
        ...notificationRoutes,
        ...dashboardRoutes,
        ...adminRoutes,
        ...checkoutRoutes,
        ...billingPageRoutes,
        ...commonRoutes,
        {
          path: 'api-docs',
          loader: () =>
            authGuard({permission: 'api.access', requireLogin: false}),
          lazy: () => import('@common/swagger/swagger-api-docs-page'),
        },
        {
          path: '*',
          element: <ShortLinkFallback />,
        },
      ],
    },
  ],
  {
    basename: getBootstrapData().settings.html_base_uri,
  },
);
